var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout-default",class:[
    {
      'is-ready': _vm.$store.getters.getPageReady,
      'is-ie': _vm.$ua.browser() === 'Internet Explorer',
      'is-firefox': _vm.$ua.browser() === 'Firefox',
      'is-edge': _vm.$ua.browser() === 'Edge',
      'is-pc': _vm.$ua.isFromPc(),
      'is-mobile': !_vm.$ua.isFromPc(),
    },
    _vm.$ua.browser(),
    _vm.$ua.deviceType(),
    _vm.$store.getters.getPageName ],attrs:{"id":"layout"}},[_c('div',{staticClass:"ld__inner"},[_c('nuxt')],1),_vm._v(" "),_c('div',{staticClass:"ld__head"},[_c('TheHeader')],1),_vm._v(" "),_c('div',{staticClass:"ld__foot-mail"},[_c('FootMail')],1),_vm._v(" "),_c('div',{staticClass:"ld__modal"},[_c('TheModal')],1),_vm._v(" "),_c('client-only',[(_vm.isDebug)?_c('Debug'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }